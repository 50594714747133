body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

p,
td,
label,
input {
  font-family: "Raleway";
}

input {
  font-family: "Raleway" !important;
}

#root h2,
h1,
h3 {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 14px;
}

.chakra-avatar {
  font-family: "Montserrat";
  font-weight: bold;
}

.chakra-modal__header {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 14px;
}

.chakra-portal h2,
h1,
h3 {
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 14px;
}

label,
button,
td,
th {
  font-family: "Montserrat" !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
}

.paginate-container {
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginate-page {
  margin: 0px 5px;
  min-width: 20px;
  max-width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginate-active {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 1px solid grey;
}

.MuiFormControlLabel-label span {
  font-size: 14px;
  white-space: nowrap;
  text-transform: capitalize !important;
}

.MuiInputLabel-shrink {
  font-size: 14px !important;
}

.MuiAppBar-root .MuiToolbar-regular{
  min-height: 50px !important;
}

.MuiAppBar-root .MuiToolbar-gutters{
  padding-left: 0px;
}

#main-content{
  padding-left: 20px;
}

.MuiFormLabel-root{
  text-transform: none !important;
}

.MuiTableCell-head{
  font-weight: 600 !important;
}
